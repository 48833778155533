import get from 'lodash/get';

function createBoatUsp(icons, texts) {
  const a = icons.find((x) => x.name === 'USP_boat-rental');
  return {
    icon: get(a, 'image.url'),
    heading: texts.hasBoats,
    value: undefined,
  };
}

function createFishSpeciesUsp({ icons = [], data }, texts) {
  const generalIcon = icons.find((x) => x.name === 'USP_Fish-species');
  const name = texts.fishSpecies;
  if (data.length > 1) {
    return {
      icon: get(generalIcon, 'image.url'),
      heading: name,
      value: data.length,
    };
  }
  if (data.length === 1) {
    return {
      icon: get(data, '[0].icon.image.url'),
      heading: get(data, '[0].name'),
      value: undefined,
    };
  }
  return null;
}

function createFishingMethodsUsp({ icons = [], data }, texts) {
  const generalIcon = icons.find((x) => x.name === 'USP_fishing-method');
  const name = texts.fishingMethods;
  if (data.length > 1) {
    return {
      icon: get(generalIcon, 'image.url'),
      heading: name,
      value: data.length,
    };
  }
  if (data.length === 1) {
    return {
      icon: get(data, '[0].icon.image.url'),
      heading: get(data, '[0].name'),
      value: undefined,
    };
  }
  return null;
}

function createAmenitiesUsp({ icons = [], data }, texts) {
  const generalIcon = icons.find((x) => x.name === 'USP_Amenities');
  const name = texts.amenities;
  if (data.length > 1) {
    return {
      icon: get(generalIcon, 'image.url'),
      heading: name,
      value: data.length,
    };
  }
  if (data.length === 1) {
    return {
      icon: get(data, '[0].icon.image.url'),
      heading: get(data, '[0].name'),
      value: undefined,
    };
  }
  return null;
}

function uspFactory(input, texts) {
  const { icons, hasBoats, fishSpecies, fishingMethods, amenities } = input;
  const usps = [];
  if (hasBoats) usps.push(createBoatUsp(icons, texts));
  if (fishSpecies)
    usps.push(createFishSpeciesUsp({ data: fishSpecies, icons }, texts));
  if (fishingMethods)
    usps.push(createFishingMethodsUsp({ data: fishingMethods, icons }, texts));
  if (amenities)
    usps.push(createAmenitiesUsp({ data: amenities, icons }, texts));
  return usps;
}
export default uspFactory;
