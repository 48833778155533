export default `
query filterCamps($filters: JSON, $withGuiding: Boolean!, $withCamps: Boolean!) {
      images(where: {
        name_in: ["guide_result", "guide_pin", "camp_pin", "camp_result"]
      }) {
        id
        name
        image {
          url
        }
      }
      icons: images(where: {name_containss: "USP_"}) {
        id
        name
        image {
          url
        }
      }
      guidings(where: $filters) @include(if: $withGuiding){
        id
        name
        slug: name_slug
        description
        fish_species {
          name
          icon: image {
            image {
              url
            }
          }
        }
        fishing_methods {
          name
          icon: image {
            image {
              url
            }
          }
        }
        amenities: guide_amenities {
          name
          icon {
            image {
              url
            }
          }
        }
        location {
          latitude
          longitude
          Address
          County
        }
        website_link: webiste_link
        profile_img {
          url
          mime
          name
          provider_metadata
          provider
          hash
        }
      }
      camps(where: $filters) @include(if: $withCamps) {
        id
        name
        slug: nameslug
        description
        fish_species {
          name
          icon: image {
            image {
              url
            }
          }
        }
        fishing_methods {
          name
          icon: image {
            image {
              url
            }
          }
        }
        housing {
          price
          currency
        }
        has_boats
        amenities {
          name
          icon: image {
            image {
              url
            }
          }
        } 
        location {
          latitude
          longitude
          Address
          County
        }
        website_link
        profile_img {
          url
          mime
          name
          provider_metadata
          provider
          hash
        }
      }
    }`;
