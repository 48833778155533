import React from 'react';
import { Flex, Box, Grid } from 'theme-ui';
import Image from 'gatsby-image';
import { UspWithIcon } from '../UspList';
import Stack from '../Stack';
import Text from '../Text';

const noop = () => {};
function LocationListItem({
  onClick = noop,
  type,
  id,
  usps = [],
  icon,
  selected,
  image,
  name,
  description,
  county,
  price,
  ...rest
}) {
  return (
    <Flex
      onClick={onClick}
      sx={{
        position: 'relative',
        border: (theme) =>
          selected === id ? `1px solid ${theme.colors.success}` : 'none',
        alignItems: 'flex-start',
        overflow: 'hidden',
        borderRadius: '5px',
        height: ['10rem', '15rem'],
        boxShadow: [`0 2px 5px rgba(0,0,0,0.1)`],
      }}
      {...rest}
    >
      <Box
        sx={{
          bg: 'accent-4',
          objectFit: 'cover',
          width: '35%',
          height: '100%',
          flexShrink: 0,
        }}
      >
        {image ? (
          <Image
            style={{
              bg: 'accent-3',
              width: '100%',
              height: '100%',
            }}
            objectFit="cover"
            objectPosition="50% 50%"
            fixed={{
              src: image.url,
              width: 300,
              height: 400,
              srcSet: '',
            }}
          />
        ) : null}
      </Box>
      <Flex
        sx={{
          flexDirection: 'column',
          flex: 1,
          height: '100%',
        }}
      >
        <Flex
          sx={{
            overflow: 'hidden',
            flex: 1,
            px: 3,
            py: 3,
            flexDirection: 'column',
          }}
        >
          <Flex
            sx={{
              position: 'absolute',
              right: '0.3rem',
              top: '0.3rem',
            }}
          >
            <Box
              sx={{
                height: 'auto',
                width: ['1.2rem', '1.5rem'],
                mr: 1,
              }}
              as="img"
              src={icon.image.url}
            />
            <Text.P sx={{ display: ['none', 'block'] }}>
              {type?.[0]?.toUpperCase()}
              {type?.slice(1)}
            </Text.P>
          </Flex>

          <Stack space={[1, 2]}>
            <Stack space="0">
              <Text.H4 as="p" sx={{ fontSize: [1] }}>
                {name}
              </Text.H4>
              <Text.Span sx={{ fontSize: 0 }} color="accent-6">
                {county}
              </Text.Span>
            </Stack>
            <Text.Span
              sx={{
                wordBreak: 'break-word',
                flexShrink: 0,
                fontSize: [0, 1],
                overflow: 'hidden',
                lineHeight: ['1rem', '1.2rem'],
                maxHeight: ['calc(1rem * 3)', 'calc(1.2rem * 3)'],
              }}
              color="accent-6"
            >
              {description}
            </Text.Span>
          </Stack>
        </Flex>
        <Box
          sx={{
            justifySelf: 'end',
          }}
        >
          <Grid
            columns={[4]}
            gap={0}
            sx={{
              flexGrow: 1,
              justifySelf: 'flex-end',
              display: ['none', 'grid'],
            }}
          >
            {usps
              .filter(Boolean)
              .map(({ icon: _icon, heading: usp, value }) => (
                <UspWithIcon
                  stackUspText
                  key={usp}
                  usp={`${value || ''} ${usp}`}
                  icon={{ url: _icon }}
                />
              ))}
          </Grid>
          <Flex
            sx={{
              alignItems: 'center',
              px: [1, 3],
              py: 2,
              justifyContent: 'space-between',
              bg: 'accent-1',
            }}
          >
            <Text.P
              sx={{
                bg: 'success-tinted',
                color: 'success-darken',
                borderRadius: '6px',
                padding: '2px 6px',
                fontSize: [0],
                fontWeight: 'bold',
              }}
            >
              {price > 0 ? `Från ${price} kr/natt` : 'Pris på förfrågan'}
            </Text.P>
            <Text.P
              variant="caps"
              sx={{
                textDecoration: 'underline',
                fontSize: 0,
                color: 'success-darken',
              }}
            >
              Läs mer
            </Text.P>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}

export default LocationListItem;
