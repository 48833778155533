import React from 'react';
import { Box } from 'theme-ui';

export function HideBelow({ target, displayType = 'block', children }) {
  const targets = {
    desktop: ['none', displayType],
    tablet: ['none', 'none', displayType],
  };

  return (
    <Box sx={{ display: targets[target] || targets.desktop }}>{children}</Box>
  );
}
