import React, { useState, useEffect } from 'react';
import FilterRow from '.';
import ExpandedFilter from './Expanded';
import useKeyValue from '../../hooks/useKeyValue';

export function getFilterCount(data) {
  return (
    Object.values(data).reduce((acc, item) => acc + item.length, 0) || null
  );
}

export default (props) => {
  const {
    filters,
    initialActiveFilters,
    setFilters = () => {},
    defaultExpanded = false,
    handleContract,
  } = props;
  const [expanded, toggle] = useState(defaultExpanded);
  const {
    state: activeFilters,
    setValue: setFilter,
    hasValue,
    reset,
  } = useKeyValue(initialActiveFilters);
  const contract = handleContract ? handleContract : () => toggle(false);
  const expand = () => toggle(true);

  useEffect(() => {
    if (!expanded) {
      setFilters(activeFilters);
    }
  }, [activeFilters]);

  function filterQuery() {
    window.scrollTo(0, 0);
    setFilters(activeFilters);
  }

  function resetQuery() {
    reset();
    setFilters({});
  }

  return (
    <>
      {!expanded ? (
        <FilterRow
          expand={expand}
          expanded={expanded}
          filters={filters}
          setFilter={setFilter}
          hasFilterValue={hasValue}
          activeFiltersCount={getFilterCount(activeFilters)}
        />
      ) : (
        <ExpandedFilter
          contract={contract}
          filters={filters}
          setFilter={setFilter}
          resetFilters={resetQuery}
          hasFilterValue={hasValue}
          updateFilter={filterQuery}
        />
      )}
    </>
  );
};
