import { useCallback, useEffect, useRef, useState } from 'react';
import queryString from '../util/queryString';

export { shapes } from '../util/queryString';

export const parse = (querystring, types) => queryString(querystring, types);

export const stringify = (params) => new URLSearchParams(params).toString();

const useUrlSync = ({ onHistoryReplace, onUpdate, types }, dependencies) => {
  if (!onHistoryReplace) throw new Error("Missing 'onHistoryReplace' prop");
  if (!onUpdate) throw new Error("Missing 'onUpdate' prop");

  const isInitialEffect = useRef(true);

  const cast = useCallback((nextParams) => parse(nextParams, types), [types]);

  const [currentParams, setCurrentParams] = useState(() => {
    if (!onUpdate) return {};
    return cast(onUpdate());
  });

  useEffect(() => {
    if (isInitialEffect.current) {
      isInitialEffect.current = false;
      return;
    }

    // Order of keys will be same
    const compareObjects = (a, b) => JSON.stringify(a) === JSON.stringify(b);

    if (onUpdate) {
      const nextParams = cast(onUpdate());
      if (!compareObjects(currentParams, nextParams)) {
        setCurrentParams(nextParams);
      }
    }
  }, dependencies);

  useEffect(() => {
    if (!currentParams) return;
    onHistoryReplace(stringify(currentParams));
  }, [currentParams]);

  return currentParams;
};

export default useUrlSync;
