import React from 'react';
import { Box } from 'theme-ui';
import { FilterButton } from '../FilterRow';
import Button from '../Button';

export function StickyFilterButton({ filterCount, onClick }) {
  return (
    <Box
      sx={{
        display: ['block', null, 'none'],
        position: 'fixed',
        zIndex: 20,
        left: '50%',
        top: '98vh',
        transform: 'translateX(-50%) translateY(-100%)',
        boxShadow: '1px 2px 3px 0px #0000002b',
      }}
    >
      {filterCount > 0 ? (
        <FilterButton onClick={onClick} activeFiltersCount={filterCount} />
      ) : (
        <Button onClick={onClick}>Filtrera</Button>
      )}
    </Box>
  );
}
