import { useState } from 'react';

function useKeyValue(initialValues = {}) {
  const [state, setState] = useState(initialValues);
  function reset(init = {}) {
    setState(init);
  }
  function hasValue(namespace, value) {
    return new Set(state[namespace]).has(value);
  }

  function setValue(namespace, value) {
    
    setState((prevState) => {
      const values = new Set(prevState[namespace] || []);
      if (values.has(value)) {
        values.delete(value);
      } else {
        values.add(value);
      }
      return {
        ...prevState,
        [namespace]: [...values],
      };
    });
  }
  return { state, setValue, reset, hasValue };
}

export default useKeyValue;
