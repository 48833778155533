import React from 'react';
import LocationListItem from '../LocationListItem';
import Link from '../Link';

export function HighlightedItem(props) {
  const { id, slug } = props;
  return (
    <Link
      sx={{
        textDecoration: 'none',
        py: 2,
      }}
      key={id}
      to={`/${slug}`}
    >
      <LocationListItem {...props} />
    </Link>
  );
}
