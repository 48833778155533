import React from 'react';
import { Flex, Box, Grid } from 'theme-ui';
import Text from '../Text';
import Button from '../FilterButton';

const hideMobile = (display) => ({
  display: ['none', display],
});
const hideDesktop = (display) => ({
  display: [display, 'none'],
});

export function FilterButton({ activeFiltersCount, onClick }) {
  return (
    <Button
      active={activeFiltersCount > 0}
      variant="outline"
      onClick={onClick}
      size="small"
    >
      <Flex sx={{ alignSelf: 'flex-end' }}>
        <Text.Span sx={hideMobile('inline-block')}>Fler filter </Text.Span>
        {activeFiltersCount ? (
          <>
            <Text.Span sx={{ px: 1, ...hideMobile('inline-block') }}>
              • {activeFiltersCount}
            </Text.Span>
            <Text.Span sx={{ px: 1, ...hideDesktop('inline-block') }}>
              {activeFiltersCount} •
            </Text.Span>
          </>
        ) : null}
        <Text.Span sx={hideDesktop('inline-block')}>Filter </Text.Span>
      </Flex>
    </Button>
  );
}

function FilterRow(props) {
  const {
    expanded,
    expand,
    filters,
    setFilter,
    hasFilterValue,
    activeFiltersCount,
  } = props;
  return (
    <Box
      sx={{
        px: 3,
        py: 3,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        bg: ['white', 'accent-3'],
        flexDirection: 'column',
        display: ['flex'],
      }}
    >
      <Box
        sx={{
          display: expanded ? 'none' : 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: ['flex-end', 'space-between'],
          width: '100%',
        }}
      >
        <Grid sx={hideMobile('grid')} columns={2}>
          <Button
            variant="outline"
            active={hasFilterValue('camp', true)}
            onClick={() => setFilter('camp', true)}
            size="small"
          >
            Camp
          </Button>
          <Button
            active={hasFilterValue('guide', true)}
            onClick={() => setFilter('guide', true)}
            variant="outline"
            size="small"
          >
            Guide
          </Button>
        </Grid>
        <Grid
          columns={['repeat(4, auto)']}
          sx={{ alignContent: 'start', ...hideMobile('grid') }}
        >
          {filters.fishingMethods.slice(0, 2).map((x) => {
            return (
              <Button
                active={hasFilterValue('fishingMethods', x.id)}
                onClick={() => setFilter('fishingMethods', x.id)}
                key={x.id}
                textOverflow="ellipsis"
                variant="outline"
                size="small"
              >
                {x.name}
              </Button>
            );
          })}
          {filters.fishSpecies.slice(0, 2).map((x) => {
            return (
              <Button
                active={hasFilterValue('fishSpecies', x.id)}
                onClick={() => setFilter('fishSpecies', x.id)}
                textOverflow="ellipsis"
                key={x.id}
                variant="outline"
                size="small"
              >
                {x.name}
              </Button>
            );
          })}
        </Grid>
        <Flex sx={{ ml: 2 }}>
          <Button
            active={activeFiltersCount > 0}
            variant="outline"
            onClick={() => expand()}
            size="small"
          >
            <Flex sx={{ alignSelf: 'flex-end' }}>
              <Text.Span sx={hideMobile('inline-block')}>
                Fler filter{' '}
              </Text.Span>
              {activeFiltersCount ? (
                <div>
                  <Text.Span sx={{ px: 1, ...hideMobile('inline-block') }}>
                    • {activeFiltersCount}
                  </Text.Span>
                  <Text.Span sx={{ px: 1, ...hideDesktop('inline-block') }}>
                    {activeFiltersCount} •
                  </Text.Span>
                </div>
              ) : null}
              <Text.Span sx={hideDesktop('inline-block')}>Filter </Text.Span>
            </Flex>
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}

export default FilterRow;
