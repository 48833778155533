import React from 'react';
import Button from '../Button';

function FilterButton({ onClick, children, active }) {
  return (
    <Button
      variant="outline"
      onClick={onClick}
      sx={{
        borderColor: 'accent-4',
        ...(active
          ? {
              bg: 'primary',
              color: 'white',
              borderColor: 'success-dark',
            }
          : {}),
      }}
      size="medium"
    >
      {children}
    </Button>
  );
}
export default FilterButton;
