import config from '../config';

export default (query) => (filters) => () =>
  fetch(config.api.graphql, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      query,
      variables: filters,
    }),
  }).then((r) => r.json());
