const shape = (val) => val;
export const shapes = {
  Array: (itemsShape = shape) => (outerVal) =>
    Array.from(outerVal.split(','), itemsShape),
  String: (outerVal) => outerVal.toString(),
  Int: (outerVal) => parseInt(outerVal, 10),
  Boolean: (outerVal) => Boolean(outerVal),
};

export default (querystring, types) => {
  const s = new URLSearchParams(querystring);

  const params = [...s.entries()].reduce((acc, row) => {
    // eslint-disable-next-line prefer-const
    let [key, values] = row;
    if (types && !types[key]) {
      return acc;
    }

    if (values.length <= 0) {
      return acc;
    }

    return {
      ...acc,
      [key]: !types ? values : types[key](values),
    };
  }, {});
  return params;
};
