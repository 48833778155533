import { get } from './get';

export function formatFilters(form) {
  const payload = {
    withCamps: get(form, 'camp', [false])[0],
    withGuiding: get(form, 'guide', [false])[0],
    filters: {},
  };
  if (!payload.withCamps && !payload.withGuiding) {
    payload.withCamps = true;
    payload.withGuiding = true;
  }

  const { fishingMethods, fishSpecies } = form;
  if (fishingMethods && fishingMethods.length > 0) {
    payload.filters.fishing_methods = {
      id_in: [...form.fishingMethods].map((x) => x),
    };
  }
  if (fishSpecies && fishSpecies.length > 0) {
    payload.filters.fish_species = {
      id_in: [...form.fishSpecies].map((x) => x),
    };
  }
  return payload;
}
