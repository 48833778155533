import React from 'react';
import { Flex } from 'theme-ui';
import Text from '../Text';

export function DataStatus(props) {
  const { loading, dataCount } = props;
  return (
    <Flex sx={{ justifyContent: 'flex-end', width: '100%', pb: 2 }}>
      <Text.H4 as="p">
        {loading ? (
          <Text.Span>Laddar data...</Text.Span>
        ) : (
          `${dataCount} resultat`
        )}
      </Text.H4>
    </Flex>
  );
}
